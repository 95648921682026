import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'
import { useWindowSize } from 'react-use'

import { SEO, Image, Button } from '../components/UI'

import styles from './AcessoAgua.module.css'

export default function AcessoAgua() {
  const handWaterClassName = cn(styles.imageWrapper, styles.handWater)
  const handWaterMobileClassName = cn(styles.imageWrapper, styles.handWaterMobile)
  const {
    banner,
    secondBanner,
    secondBannerMobile,
    infraImage,
    infraMobileImage,
    gestaoImage,
    gestaoMobileImage,
    fortalecimentoImage,
    fortalecimentoMobileImage,
    waterDrop,
    amazoniaImage,
    casaRioAmazoniaImage,
    espiritoSantoImage,
    regiaoMetropolitanaImage,
    rioSaoFranciscoImage
  } = useStaticQuery(
    graphql`
      query AcessoAgua {
        banner: file(relativePath: { eq: "acesso-agua/glass-water.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        secondBanner: file(relativePath: { eq: "acesso-agua/hand-water.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        secondBannerMobile: file(relativePath: { eq: "acesso-agua/hand-water-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        infraImage: file(relativePath: { eq: "acesso-agua/infraestrutura.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        infraMobileImage: file(relativePath: { eq: "acesso-agua/infraestrutura-mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gestaoImage: file(relativePath: { eq: "acesso-agua/gestao.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gestaoMobileImage: file(relativePath: { eq: "acesso-agua/gestao-mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        fortalecimentoImage: file(relativePath: { eq: "acesso-agua/fortalecimento.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        fortalecimentoMobileImage: file(relativePath: { eq: "acesso-agua/fortalecimento-mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        waterDrop: file(relativePath: { eq: "acesso-agua/water-drop.svg" }) {
          publicURL
        }
        amazoniaImage: file(relativePath: { eq: "acesso-agua/amazonia.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        casaRioAmazoniaImage: file(relativePath: { eq: "acesso-agua/casa-rio-amazonia.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        espiritoSantoImage: file(relativePath: { eq: "acesso-agua/espirito-santo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        regiaoMetropolitanaImage: file(relativePath: { eq: "acesso-agua/regiao-metropolitana.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rioSaoFranciscoImage: file(relativePath: { eq: "acesso-agua/rio-sao-francisco.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const windowSize = useWindowSize(initialWidth)
  const isMobile = windowSize.width <= 890

  const answerContainerClassName = cn('container', styles.answer)
  const whatWeDoContainerClassName = cn('container', styles.whatWeDo)
  const withNumberClassName = cn('container', styles.withNumberContainer)
  const regionsClassName = cn('container', styles.regions)

  return (
    <div className={styles.content}>
      <SEO title='Acesso à Água' />

      <section className={styles.container}>
        <figure className={styles.imageWrapper}>
          <Image
            alt='Copo de Água.'
            className={styles.image}
            fluid={banner.childImageSharp.fluid}
          />
        </figure>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>
            acesso <br />à água
          </h2>
          <div className={styles.text}>
            <p>E aí, quantos litros de água você bebe todos os dias?</p>
          </div>
        </div>
      </section>

      <section className={answerContainerClassName}>
        <h2 className={styles.answerTitle}>
          essa resposta pode variar... muito.
          <br />e nem sempre por vontade própria.
        </h2>
        <div className={styles.answerContent}>
          <div className={styles.answerParagraph}>
            <p>
              Todo mundo sabe que a água é essencial para que a gente fique vivo e saudável. Num mundo ideal, ela
              estaria bem cuidada e presente em 100% dos lares. Mas na real não é bem assim. Aqui no Brasil, o fato é:
              faltam serviços que garantam o acesso à água potável e de qualidade para todos.
            </p>
            <p>
              Podemos até dizer que essa desigualdade é tão grande quanto o nosso país, principalmente quando a gente
              compara as áreas urbanas com as rurais. Geralmente nas comunidades rurais o acesso à água tem condições
              ainda piores.
            </p>
          </div>
          <div className={styles.answerParagraph2}>
            <p>
              Mas se a água, que é tão importante para a qualidade de vida da população, não está presente na vida de
              todos como deveria, como podemos ajudar a resolver esse impasse?
            </p>
            <p>
              Abraçando o desafio <br />
              do acesso à água e sua <br />
              sustentabilidade no Brasil.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.container}>
        <figure className={handWaterClassName}>
          <Image
            alt='Mão molhada na superfície da água.'
            className={styles.image2}
            fluid={secondBanner.childImageSharp.fluid}
          />
        </figure>

        <figure className={handWaterMobileClassName}>
          <Image
            alt='Mão molhada na superfície da água.'
            className={styles.image2}
            fluid={secondBannerMobile.childImageSharp.fluid}
          />
        </figure>

        <div className={styles.textWrapper2}>
          <h2 className={styles.title}>
            água <br />
            +acesso
          </h2>
          <div className={styles.text2}>
            <p>
              Nós e mais 13 parceiros decidimos encarar juntos o desafio do acesso à água. Por quê? Porque quando a
              gente pensa no tamanho do Brasil e nas dificuldades que isso traz, entendemos que a união faz a força, e a
              diferença também.
            </p>
            <p>
              O Água+ Acesso é uma aliança que nasceu em março de 2017 para levar água até as comunidades rurais do
              país. Temos 4 pilares - integrar, inovar, impulsionar e influenciar - que são a base das nossas 3
              principais ações - infraestrutura, gestão e fortalecimento.
            </p>
            <p>Hoje, estamos em 8 estados, garantindo água potável para 77 mil pessoas em mais de 200 comunidades.</p>
          </div>
        </div>
      </section>

      <section className={whatWeDoContainerClassName}>
        <h2 className={styles.whatWeDoTitle}>
          o que <br />a gente faz?
        </h2>
        <div className={styles.whatWeDoList}>
          <div className={styles.whatWeDoListItem}>
            <figure className={styles.whatWeDoItemImageWrapper}>
              <Image
                alt='Vista aérea de estação de tratamento de água.'
                className={styles.whatWeDoItemImage}
                fluid={isMobile ? infraMobileImage.childImageSharp.fluid : infraImage.childImageSharp.fluid}
              />
            </figure>
            <div className={styles.whatWeDoItemText}>
              <h2>Infraestrutura</h2>
              <h3>Canos, tubulações, cisternas: construímos o que é preciso para o acesso à água</h3>
              <p>
                A gente constrói ou reforma os sistemas de abastecimento e as redes de distribuição de água nessas
                regiões. Sempre levamos em consideração o que é melhor para as características dos próprios moradores e
                de cada lugar.
              </p>
            </div>
          </div>
          <div className={styles.whatWeDoListItem}>
            <figure className={styles.whatWeDoItemImageWrapper}>
              <Image
                alt='Índio andando de canoa.'
                className={styles.whatWeDoItemImage}
                fluid={isMobile ? gestaoMobileImage.childImageSharp.fluid : gestaoImage.childImageSharp.fluid}
              />
            </figure>
            <div className={styles.whatWeDoItemText}>
              <h2>Gestão</h2>
              <h3>Tornamos os moradores os principais cuidadores desses pontos de acesso</h3>
              <p>
                Os moradores são a grande chave para mantermos esses sistemas funcionando no dia a dia da comunidade.
                Por isso, a gente cria modelos de gestão totalmente baseados na participação ativa deles.
              </p>
            </div>
          </div>
          <div className={styles.whatWeDoListItem}>
            <figure className={styles.whatWeDoItemImageWrapper}>
              <Image
                alt='Mãos apoiadas em uma tubulação de água.'
                className={styles.whatWeDoItemImage}
                fluid={
                  isMobile ? fortalecimentoMobileImage.childImageSharp.fluid : fortalecimentoImage.childImageSharp.fluid
                }
              />
            </figure>
            <div className={styles.whatWeDoItemText}>
              <h2>Fortalecimento</h2>
              <h3>Educamos os moradores sobre as melhores formas de cuidado</h3>
              <p>
                Para que todos entendam seu papel e para que nossos ideais sejam fortalecidos, a gente promove eventos e
                ações comunitárias nessas regiões. Além disso, somos uma ponte entre governo e empresas quando o assunto
                é acesso à água.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.withNumber}>
        <div className={withNumberClassName}>
          <h2 className={styles.withNumberTitle}>
            água+acesso
            <br />
            <span>em números</span>
          </h2>
          <div className={styles.withNumberList}>
            <div className={styles.withNumberItem}>
              <div className={styles.withNumberYear}>
                <h2>2017</h2>
                <div className={styles.withNumberItemDivisor}>
                  <div className={styles.withNumberItemDivisorBorder} />
                  <img
                    alt={"Ícone de gota d'água crystal"}
                    src={waterDrop.publicURL}
                    className={styles.withNumberItemIcon}
                  />
                </div>
              </div>
              <div className={styles.withNumberItemText}>
                <p>
                  <span>3</span> estados
                </p>
                <p>
                  <span>15</span> comunidades
                </p>
                <p>
                  <span>4 mil</span> pessoas
                </p>
                <p>
                  <span>9</span> parceiros
                </p>
              </div>
            </div>
            <div className={styles.withNumberItem}>
              <div className={styles.withNumberYear}>
                <h2>2018</h2>
                <div className={styles.withNumberItemDivisor}>
                  <div className={styles.withNumberItemDivisorBorder} />
                  <img
                    alt={"Ícone de gota d'água crystal"}
                    src={waterDrop.publicURL}
                    className={styles.withNumberItemIcon}
                  />
                </div>
              </div>
              <div className={styles.withNumberItemText}>
                <p>
                  <span>8</span> estados
                </p>
                <p>
                  <span>100</span> comunidades
                </p>
                <p>
                  <span>38 mil</span> pessoas
                </p>
                <p>
                  <span>14</span> parceiros
                </p>
                <p>
                  <span>1</span> coinvestidor
                </p>
                <p className={styles.withNumberItemTextLegend}>(instituto Igua)</p>
              </div>
            </div>
            <div className={styles.withNumberItem}>
              <div className={styles.withNumberYear}>
                <h2>2019</h2>
                <div className={styles.withNumberItemDivisor}>
                  <div className={styles.withNumberItemDivisorBorder} />
                  <img
                    alt={"Ícone de gota d'água crystal"}
                    src={waterDrop.publicURL}
                    className={styles.withNumberItemIcon}
                  />
                </div>
              </div>
              <div className={styles.withNumberItemText}>
                <p>
                  <span>+90</span> comunidades
                </p>
                <p className={styles.withNumberItemTextLegend}>(190 no total)</p>
                <p>
                  <span>+38 mil</span> pessoas
                </p>
                <p className={styles.withNumberItemTextLegend}>(75 mil no total)</p>
              </div>
            </div>
            <div className={styles.withNumberItem}>
              <div className={styles.withNumberYear}>
                <h2>2020</h2>
                <div className={styles.withNumberItemDivisor}>
                  <div className={styles.withNumberItemDivisorBorder} />
                  <img
                    alt={"Ícone de gota d'água crystal"}
                    src={waterDrop.publicURL}
                    className={styles.withNumberItemIcon}
                  />
                </div>
              </div>
              <div className={styles.withNumberItemText}>
                <p>
                  <span>+100 mil</span> pessoas
                </p>
                <p>beneficiadas</p>
                <p>
                  <span>+250</span> comunidades
                </p>
              </div>
            </div>
          </div>
          <Button
            isMuted
            className={styles.withNumberButton}
            href='http://aguamaisacesso.com.br/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Descubra mais sobre o água+ acesso
          </Button>
        </div>
      </section>

      <section className={styles.container}>
        <figure className={styles.imageWrapper}>
          <Image
            alt='Amazônia'
            className={styles.image2}
            fluid={amazoniaImage.childImageSharp.fluid}
          />
        </figure>
        <div className={styles.textWrapper3}>
          <h2 className={cn(styles.title, styles.title3)}>
            água <br />
            +disponibilidade
          </h2>
          <div className={styles.text3}>
            <p>
              Para que o acesso à água potável seja uma realidade, antes a gente precisou entender uma coisa: essa água
              tem que existir em quantidade suficiente.
            </p>
            <h2>Como garantir isso?</h2>
            <p>
              Com o Água+ Disponibilidade, desde 2009, nós e alguns parceiros apoiamos projetos que trabalham na
              preservação dos recursos hídricos do nosso país. Hoje nosso maior projeto está na Amazônia, mas também
              mantemos iniciativas nas regiões próximas às nossas fábricas.
            </p>
            <h2>O resultado desses esforços?</h2>
            <p>
              Desde 2013, a gente devolve ao meio ambiente a mesma quantidade de água que utilizamos na produção das
              nossas bebidas.
            </p>
          </div>
        </div>
      </section>

      <section className={regionsClassName}>
        <div className={styles.region}>
          <figure className={styles.regionImageWrapper}>
            <Image
              alt='Casa perto de rio na Amazônia.'
              className={styles.regionImage}
              fluid={casaRioAmazoniaImage.childImageSharp.fluid}
            />
          </figure>
          <div className={styles.regionText}>
            <h2>Amazônia</h2>
            <h3>Redução de 75% no desmatamento de áreas mapeadas</h3>
            <p>
              O Bolsa Floresta, programa da FAS (Fundação Amazonas Sustentável) que apoiamos desde 2009, preserva mais
              de 100 mil hectares de floresta em 16 unidades protegidas. Suas ações mantêm a floresta de pé, preservam o
              volume de água das bacias hidrográficas e asseguram a nossa neutralidade hídrica.
            </p>
            <p>
              O programa já beneficiou 40 mil pessoas e reduziu em 75% o desmatamento dessas áreas, além de garantir
              renda para os moradores participantes, que são remunerados ao se comprometerem com ele.
            </p>
          </div>
        </div>
        <div className={styles.region}>
          <figure className={styles.regionImageWrapper}>
            <Image
              alt='Rio São Francisco'
              className={styles.regionImage}
              fluid={rioSaoFranciscoImage.childImageSharp.fluid}
            />
          </figure>
          <div className={styles.regionText}>
            <h2>Nordeste</h2>
            <h3>Educação ambiental, reflorestamento e revitalização de nascentes</h3>
            <p>
              Desde 2017 apoiamos o Instituto Fábrica de Florestas, que oferece educação ambiental nos estados da Bahia,
              Rio de Janeiro e São Paulo.
            </p>
            <p>
              São 3 os projetos do IFF: o EcoParque Sauípe, que oferece atividades de educação ambiental, ecoturismo,
              treinamentos e eventos; o Fábrica de Florestas, que produz mudas nativas da Mata Atlântica e da Caatinga
              para a restauração florestal e conscientização sobre a importância da preservação; o Reviveiros Velho
              Chico, que desenvolve, através de viveiros ecológicos em regiões estratégicas de alguns dos principais
              afluentes do Rio São Francisco, a recuperação e revitalização de nascentes e de áreas degradadas.
            </p>
            <Button
              isMuted
              className={styles.regionButton}
              href='http://fabricadeflorestas.org.br/programas/'
              rel='noopener noreferrer'
              target='_blank'
            >
              Saiba mais
            </Button>
          </div>
        </div>
        <div className={styles.region}>
          <figure className={styles.regionImageWrapper}>
            <Image
              alt='Paisagem no estado do Espírito Santo.'
              className={styles.regionImage}
              fluid={espiritoSantoImage.childImageSharp.fluid}
            />
          </figure>
          <div className={styles.regionText}>
            <h2>Espírito Santo</h2>
            <h3>Recuperação de uma área de 150 hectares</h3>
            <p>
              Junto com a Leão Alimentos e Bebidas, apoiamos um programa-piloto para reflorestar uma área de 150
              hectares no norte do estado. O Comitê de Bacias do Barra Seca e Foz do Rio Doce, que também é parceiro da
              iniciativa, criou uma comissão para acompanhar 51 produtores, espalhados por 48 propriedades.
            </p>
            <p>
              O objetivo do programa é levar água para a agricultura da região, que foi atingida pela seca nos útlimos
              anos. Para se ter uma ideia, eles já produziram 95% do maracujá utilizado pela Leão, mas hoje só conseguem
              entregar 5% da fruta.
            </p>
          </div>
        </div>
        <div className={styles.region}>
          <figure className={styles.regionImageWrapper}>
            <Image
              alt='Região Metropolitana.'
              className={styles.regionImage}
              fluid={regiaoMetropolitanaImage.childImageSharp.fluid}
            />
          </figure>
          <div className={styles.regionText}>
            <h2>Regiões Metropolitanas</h2>
            <h3>Abastecimento de 63 milhões de pessoas em 250 cidades</h3>
            <p>
              Um dos projetos que apoiamos é a Coalizão Cidades pela Água, que também reúne empresas como Ambev e a
              PepsiCo. A Coalizão é uma iniciativa da TNC (The Nature Conservancy) para aumentar disponibilidade da água
              em 12 regiões metropolitanas - entre elas, Belo Horizonte, Curitiba, Rio de Janeiro, São Paulo, Vitória e
              Distrito Federal.
            </p>
            <p>
              A gente recupera áreas estratégicas para o abastecimento de água e atende 21 bacias hidrográgicas, que são
              responsáveis pela água de quase 63 milhões de pessoas em mais 250 cidades.
            </p>
            <Button
              isMuted
              className={styles.regionButton}
              href='https://www.tnc.org.br/o-que-fazemos/nossas-iniciativas/coalizao-cidades-pela-agua/'
              rel='noopener noreferrer'
              target='_blank'
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </section>
    </div>
  )
}
